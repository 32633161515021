<template>
  <b-container v-if="renderPage">
    <b-overlay
      :show="show_loading"
      rounded="lg"
      :opacity="1"
    >
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <strong>{{ msg }}, Aguarde...</strong>
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
          <b-spinner
            type="grow"
            variant="primary"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
        </div>
      </template>
      <b-col
        class="mr-auto ml-auto"
        xl="6"
        md="12"
        sm="12"
        lg="6"
      >
        <b-card title="Criar Novo Perfil">
          <validation-observer ref="simpleRules">
            <b-form
              novalidate
              class="needs-validation"
              @submit.prevent
            >
              <b-row>
                <b-col
                  sm="12"
                  lg="12"
                  md="12"
                  class="mb-1"
                >
                  <label for="v-nome-perfil">Nome do Perfil:</label>
                  <validation-provider
                    #default="{ errors }"
                    name="profile"
                    rules="required"
                  >
                    <b-form-input
                      id="v-nome-perfil"
                      v-model="profileName"
                      placeholder="Informe o nome do Perfil"
                      :state="
                        errors.length > 0 ? false : profileName ? true : null
                      "
                    />
                    <b-form-valid-feedback>
                      Campo Preenchido!
                    </b-form-valid-feedback>
                    <b-form-invalid-feedback>
                      Por favor Preencha o campo
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  sm="12"
                  lg="12"
                  md="12"
                >

                  <b-form-group label="Escolha os Grupos">
                    <b-form-checkbox-group
                      v-model="groupsSelected"
                      inline
                      stacked
                      :options="groupsOptions"
                      style="column-count: 2"
                      switches
                    />
                  </b-form-group>
                </b-col></b-row>
              <b-row>
                <b-col md="6">
                  <b-button
                    class="mt-1"
                    variant="primary"
                    block
                    @click="createNewProfile"
                  >Cadastrar</b-button>
                </b-col>
                <b-col md="6">
                  <b-button
                    class="mt-1"
                    variant="primary"
                    block
                    :to="{ name: 'usuarios' }"
                  >Voltar</b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-overlay>
  </b-container>
</template>
<script>
import {
    BButton,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    BCard,
    BContainer,
    BForm,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BSpinner,
    BOverlay,
    BIcon,
    BFormCheckboxGroup,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';

import UserAtomService from '@/service/user-atom';

export default {
    components: {
        BButton,
        BFormGroup,
        BFormInput,
        BCol,
        BRow,
        BCard,
        BContainer,
        BForm,
        BFormValidFeedback,
        BFormInvalidFeedback,
        BSpinner,
        BOverlay,
        ValidationProvider,
        ValidationObserver,
        BIcon,
        BFormCheckboxGroup,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            renderPage: true,
            profileName: null,
            required,
            email,
            permissions: [],
            selected_permission: [],
            selectedPermissionDashboard: [],
            show_loading: false,
            msg: ' ',
            options: {},
            groups: [],
            groupsSelected: [],
        };
    },

    computed: {
        groupsOptions() {
            return this.groups.map(item => ({
                value: item.id,
                text: item.name,
            }));
        },
    },

    mounted() {
        this.getGroups();
    },

    methods: {
        async getGroups() {
            this.groups = (await UserAtomService.showGroups()).data.data;
        },
        async createNewProfile() {
            const response = await this.confirmAnAction('Deseja realmente criar esse perfil?');

            if (!response) return;
            this.msg = 'Criando novo Perfil';
            this.show_loading = true;

            const { profileName, groupsSelected } = this;

            const { status } = await UserAtomService.createProfile({
                name: profileName,
                groupIds: groupsSelected,
            });

            if (status === 200) {
                this.modalSuccess('Perfil cadastrado com sucesso');
            } else {
                this.modalError(`Não foi Cadastrar o perfil ${profileName}!`);
            }
            this.show_loading = false;
        },
    },
};
</script>
  <style lang="scss" scoped>
  @import "@core/scss/vue/pages/new-client.scss";
  </style>
